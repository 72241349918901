import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { AuthTokens } from '@services/api/axios'

export const useIsLoggedIn = () => {
  const { value: token } = useAuthToken()

  return useMemo(() => !!token, [token])
}

export const useAuthToken = () => ({
  get: () => {
    return localStorage.getItem(AuthTokens.auth)
  },
  set: (token: string) => {
    localStorage.setItem(AuthTokens.auth, token)
  },
  value: localStorage.getItem(AuthTokens.auth)
})

export const useRefreshToken = () => ({
  set: (token: string) => {
    localStorage.setItem(AuthTokens.refresh, token)
  },
  value: localStorage.getItem(AuthTokens.refresh)
})

export const useMeiliToken = () => ({
  get: () => {
    return localStorage.getItem(AuthTokens.meili)
  },
  getUrl: () => {
    return localStorage.getItem(AuthTokens.meiliUrl)
  },
  set: (token: string, url: string) => {
    localStorage.setItem(AuthTokens.meili, token)
    localStorage.setItem(AuthTokens.meiliUrl, url)
  },
  value: localStorage.getItem(AuthTokens.meili)
})

export const useLogout = () => {
  const { set: setAuthToken } = useAuthToken()
  const { set: setRefreshToken } = useRefreshToken()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const logout = async () => {
    setAuthToken('')
    setRefreshToken('')
    navigate('/login')
    await queryClient.invalidateQueries()
  }

  return {
    logout
  }
}
