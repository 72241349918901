import {
  Dialog,
  Transition
} from '@headlessui/react'
import {
  ArrowLeftIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import { ModalProps } from '@components/modals/modal/modal.interfaces'

const Modal = ({
  center = false,
  children,
  fullscreen = false,
  onClose,
  onReturn,
  open,
  setOpen,
  size = 'normal',
  title
}: ModalProps) => {
  const modalStyles = fullscreen ? 'md:p-12' : `rounded-lg border-4 border-gray-100 bg-gray-100 text-left shadow-xl w-full ${size === 'large' ? 'lg:max-w-[65rem]' : size === 'medium' ? 'lg:max-w-[40rem]' : 'lg:max-w-lg sm:max-w-sm'}`
  const closeStyles = fullscreen ? '-top-6 md:top-2 bg-gray-100 hover:border-gray-900 hover:bg-white' : 'text-gray-900 hover:bg-white'

  const handleClose = (boolean) => {
    setOpen(boolean)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div aria-hidden='true' className='fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity' onClick={handleClose} />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto max-h-full'>
          <div className={`flex min-h-full h-full justify-center text-center sm:p-0 ${center ? 'items-center' : ' items-start'}`}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={`relative transition-all max-h-full overflow-hidden ${modalStyles}`}>
                <div className='px-4 py-2.5 flex items-center justify-between relative min-h-[44px] bg-white'>
                  {onReturn && (
                    <button className='hover:bg-white rounded-md' onClick={onReturn}>
                      <ArrowLeftIcon className='w-6 h-6' />
                    </button>
                  )}

                  <div className='text-gray-700 font-medium flex items-center justify-center gap-2 line-clamp-1'>
                    {title && title}
                  </div>

                  <button
                    className={`flex rounded-md ${closeStyles}`}
                    onClick={() => {
                      handleClose(false)
                    }}
                  >
                    <XMarkIcon className='w-6 h-6' />
                  </button>
                </div>

                <div className={`rounded-lg relative max-h-[90vh] overflow-auto ${fullscreen ? 'border-2 border-gray-100' : 'bg-white'}`}>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
