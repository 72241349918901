import React, {
  useEffect,
  useState
} from 'react'

import Modal from '@components/modals/modal'
import changelogData from '@root/changelog.json'
import useLocalStorage from '@services/hooks/local-storage'

const ChangelogModal = () => {
  const [lastSeenVersion, setLastSeenVersion] = useLocalStorage('lastSeenVersion', 118)
  const [open, setOpen] = useState(false)

  const newChangelogEntries = changelogData.filter(entry => entry.available && Number(entry.versionCode) > Number(lastSeenVersion))

  useEffect(() => {
    if (newChangelogEntries.length > 0) {
      setOpen(true)
    }
  }, [])

  const handleClose = () => {
    if (newChangelogEntries.length > 0) {
      setLastSeenVersion(newChangelogEntries[0].versionCode)
    }
    setOpen(false)
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR')
  }

  return (
    <div>
      <Modal center onClose={handleClose} open={open} setOpen={setOpen} size='large' title='Dernières nouveautés'>
        <div className='flex flex-col p-8 space-y-10'>
          {newChangelogEntries.map(entry => (
            <div key={entry.version}>
              <h2 className='text-lg font-semibold text-gray-700 mb-4'>
                {`Version ${entry.version} - `}

                <span className='text-sm text-gray-600 mb-4'>{formatDate(entry.date)}</span>
              </h2>

              <div className='border-t border-gray-300'>
                <ul className='list-disc list-inside text-gray-700 mt-4'>
                  {entry.changes.map((change, index) => (
                    <li className='mb-2' key={index}>{change}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default ChangelogModal
