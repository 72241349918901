import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import { NotificationType } from '@components/notification/notification.interfaces'
import { useNotificationStore } from '@services/stores/notification/notification'

const Notification = () => {
  const {
    hideNotification,
    notificationDisplayed,
    notificationMessage,
    notificationTitle,
    notificationType
  } = useNotificationStore()

  const types = {
    [NotificationType.success]: {
      icon: CheckCircleIcon,
      textColor: 'text-green-400'
    },
    [NotificationType.error]: {
      icon: XCircleIcon,
      textColor: 'text-red-400'
    },
    [NotificationType.info]: {
      icon: ExclamationCircleIcon,
      textColor: 'text-yellow-500'
    }
  }
  const NotificationIcon = types[notificationType].icon

  return (
    <div
      aria-live='assertive'
      className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'
    >
      <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

        <Transition
          as={Fragment}
          enter='transform ease-out duration-300 transition'
          enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
          enterTo='translate-y-0 opacity-100 sm:translate-x-0'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          show={notificationDisplayed}
        >
          <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0'>
                  <NotificationIcon aria-hidden='true' className={`h-6 w-6 ${types[notificationType].textColor}`} />
                </div>

                <div className='ml-3 w-0 flex-1 pt-0.5'>
                  <p className='text-sm font-medium text-gray-900'>{notificationTitle}</p>

                  <p className='mt-1 text-sm text-gray-500'>{notificationMessage}</p>
                </div>

                <div className='ml-4 flex flex-shrink-0'>
                  <button
                    className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={() => {
                      hideNotification()
                    }}
                    type='button'
                  >
                    <span className='sr-only'>{'Close'}</span>

                    <XMarkIcon aria-hidden='true' className='h-5 w-5' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default Notification
