import classNames from 'classnames'
import * as React from 'react'

import { ButtonLoaderProps as Props } from '@components/button-loader/button-loader.interfaces'

const ButtonLoader = ({
  dark = false,
  size = 'size-4',
  wrapperClassName
}: Props) => {
  const classes = classNames(
    dark ? 'text-black' : 'text-white',
    `animate-spin ${size}`
  )

  return (
    <span className={wrapperClassName}>
      <svg className={classes} fill='none' viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />

        <path className='opacity-75' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          fill='currentColor'
        />
      </svg>
    </span>
  )
}

export default ButtonLoader
