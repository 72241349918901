import { HomeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'

const Home = () => {
  const { t: translateGeneral } = useTranslation('general')

  return (
    <Layout title={translateGeneral('home')}>
      <Breadcrumbs />

      <Guard errorPage={false}>
        <SectionTitle icon={HomeIcon} title={'Home'} />

        <>{'Home'}</>
      </Guard>
    </Layout>
  )
}

export default Home
