import {
  Home,
  Login
} from '@pages'
import * as Sentry from '@sentry/react'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router'
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom'

// eslint-disable-next-line no-restricted-imports
import packageInfo from '../package.json'
import { useMe } from '@services/api/auth/use-me'
import { useAuthToken } from '@services/hooks/auth-token'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false
      })
    ],
    release: `tosnoops-admin@${packageInfo.version}`,

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1,

    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api.tosnoops.staging.tilted-labs\.fr/],

    // Performance Monitoring
    tracesSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const DEFAULT_STALE_TIME = 5 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: DEFAULT_STALE_TIME
    }
  }
})

const Protected = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const { value: authToken } = useAuthToken()
  useMe()

  if (!authToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate replace state={{
        from: location
      }} to='/login'
      />
    )
  }

  return children
}

function App () {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Login />} path='/login' />

          <Route element={<Protected><Home /></Protected>} path='/' />
        </Routes>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
