import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'

import { SectionTitleProps } from '@components/section-title/section-title-interfaces'

const SectionTitle = ({
  icon: Icon,
  title
}: SectionTitleProps) => {
  return (
    <div className='flex gap-4 items-center px-4 sm:px-6 md:px-8 mb-6'>
      {Icon && (
        <div className='p-2 bg-primary rounded-full'>
          <Icon className={`size-5 text-white ${Icon === ChatBubbleOvalLeftIcon ? 'stroke-white' : ''}`} />
        </div>
      )}

      <div className='font-semibold text-gray-700 text-3xl flex gap-4 items-center'>
        {title}
      </div>
    </div>
  )
}

export default SectionTitle
